.welcome-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  background-size: cover;
  background-position: center;
  position: relative;
  border-radius: 10px;
}

.welcome-text {
  color: white;
  text-align: left;
  position: absolute;
  left: 60px;
  top: 20px;
}
.welcome-text h1 {
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  color: #1d2939;
}

.welcome-text p {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  color: #1d2939;
}

.welcome-text2 {
  padding:10px 26px;
  background-color: rgba(0, 0, 0, 0.6);
  color: rgba(252, 252, 253, 1);
  text-align: left;
  position: absolute;
  left: 0px;
  top: 20px;
  border-top-right-radius:6px;
  border-bottom-right-radius:6px;
}
.welcome-text2 h1 {
  margin-bottom: 12px;
  font-size: 30px;
  font-weight: 700;
  /* color: #1d2939; */
}

.welcome-text2 p {
  margin: 0;
  font-size: 24px;
  font-weight: 700;
  /* color: #1d2939; */
}

.slick-slide { 
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px; /* Add padding here */
  box-sizing: border-box; /* Ensure padding doesn't increase the width */
}

.slick-list {
    margin: 0; /* Ensure there's no extra margin */
    padding: 0; /* Ensure there's no extra padding */
}

.carousel-item {
  height: 185px;
  width: 185px !important;
  
  margin: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  border-radius: 10px;
}

.carousel-item:hover {
  background-color: #1d2939;
  cursor: pointer;
}

.carousel-item img {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 10px;
}

.plus-icon {
    background-color: black;
    height: 100px;
    width: 100px;
    color: white;
    font-size: 36px;
    font-weight: bold;
    margin-right: 8px; /* Adjust margin as needed */
  }
