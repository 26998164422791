.vs-logo {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.sidebar {
  height: 100%;
  padding: 17px 5px;
  text-align: center;
  width: 60px;
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.sidebar .top-section {
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 25px;
}

.sidebar .bottom-section {
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}



.signin-main {
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/hrms_login.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.jobapplication-bg {
  background-image: url("../assets/Job_discription_Background_Pic.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 220px;
}

.jobapplication-logo {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.logo_img {
  width: 30%;
  height: 20%;
}


.signin-left p {
  color: white;
  font-size: 1.5rem;
  margin-top: 2rem;
  text-align: center;
}



.searchbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;
  flex-wrap: wrap;
  padding: 5px;
}

.searchbar input {
  padding: 6px 10px !important;
}

.drawer-form-container {
  padding: 1rem 1rem;
}

.master-heading {
  color: #37668f !important;
  font-weight: bold !important;
}

.view-left,
.view-right,
.application-status-box {
  padding: 6px 12px !important;
  border-radius: 10px !important;
  background-color: white !important;
}

.view-left:hover,
.view-right:hover,
.application-status-box:hover {
  box-shadow: rgba(0, 0, 0, 0.23) 0px 4px 4px !important;
}

/* overriding mui  */

.css-n3fyjk-MuiDataGrid-root .MuiDataGrid-container--top [role="row"],
.css-mbboce-MuiTableRow-root,
.css-1ne8j0w {
  background-color: var(--common-bg-color) !important;
  border-bottom: 1px solid #b9e6fe !important;
  font-size: 16px !important;
  border-right: 1px solid #b9e6fe;

}


.css-n3fyjk-MuiDataGrid-root .MuiDataGrid-row,
.css-ooo7gw .MuiDataGrid-row {
  color: #1d2939 !important;
  background-color: white;
  font-size: 14px;
}

.css-n3fyjk-MuiDataGrid-root .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row:hover,
.css-ooo7gw .MuiDataGrid-row:hover {
  font-size: large;
  background-color: white;
  font-size: 15px;
  box-shadow: rgba(0, 0, 0, 0.23) 1px 2px 2px !important;
  margin-bottom: 2px;
}

.css-n3fyjk-MuiDataGrid-root,
.css-ooo7gw {
  border-color: #b9e6fe !important;
  border-radius: 4px !important;
}

.css-rqglhn-MuiTable-root,
.css-1owb465 {
  border: 1px solid #b9e6fe !important;
  border-radius: 4px !important;
}

.css-rqglhn-MuiTable-root,
.css-1owb465 {
  border-collapse: separate !important;
}

.css-13xfq8m-MuiTabPanel-root {
  padding: 0 !important;
}

.css-hg18dx-MuiButtonBase-root-MuiTab-root,
.css-13cp4kv {
  font-size: 1rem !important;
  color: #98a2b3 !important;
}

.css-hg18dx-MuiButtonBase-root-MuiTab-root.Mui-selected,
.css-13cp4kv.Mui-selected {
  color: #026aa2 !important;
  background-color: white !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0.3, 0.3) !important;
}

.css-1897kd5 {
  padding: 0 !important;
}

/* here------------------ */

/* No changes needed for layout.css unless targeting MUI components specifically */

.master-heading {
  color: #37668f !important;
  font-weight: bold !important;
}

.view-left,
.view-right,
.application-status-box {
  padding: 6px 12px !important;
  border-radius: 10px !important;
  background-color: white !important;
}

.view-left:hover,
.view-right:hover,
.application-status-box:hover {
  box-shadow: rgba(0, 0, 0, 0.23) 0px 4px 4px !important;
}

.css-yq6j56 {
  padding: 12px;
}

.css-ooo7gw .MuiDataGrid-container--top [role="row"],
.css-ooo7gw .MuiDataGrid-container--bottom [role="row"] {
  background-color: var(--common-bg-color) !important;
  border-bottom: 1px solid #b9e6fe !important;
  /* margin-bottom: 1.5px !important; */
  font-size: 16px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
.ql-editor,
.css-qiwgdb,
.css-qiwgdb.MuiSelect-select,
.css-1x5jdmq,
.css-segi59,
.css-1x6bjyf-MuiAutocomplete-root,
.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root,
.css-z3c6am-MuiFormControl-root-MuiTextField-root,
.css-aq7zae-MuiFormControl-root-MuiTextField-root,
.css-1ab2xsx,
.css-18col2x .MuiOutlinedInput-root,
.css-15kq27i {
  background-color: #fcfcfd !important;
  border-color: 1px solid #98a2b3 !important;
}

.css-1aquho2-MuiTabs-indicator {
  background: none !important;
}



.css-i4bv87-MuiSvgIcon-root,
.css-vubbuv {
  height: 20px !important;
  height: 20px !important;
}

.css-hcdzbm-MuiAutocomplete-root,
.css-fzem9i-MuiAutocomplete-root {
  padding: 0 !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root.Mui-disabled,
.css-1ujsas3.Mui-disabled {
  background-color: #98A2B3;
  color: #E4E7EC;
}


.css-ypiqx9-MuiDialogContent-root {
  padding: 0 !important;
}


/* --input  */

.css-hcdzbm-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
  padding: 0 !important;
}

.css-hcdzbm-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding: 10px 15px !important;
}


.css-1ygcj2i-MuiTableCell-root {
  font-size: 16px !important;
}

/* .css-n3fyjk-MuiDataGrid-root .MuiDataGrid-columnHeaderDraggableContainer,.css-ooo7gw{
  border-right: 1px solid #b9e6fe !important;
} */

/* .css-ooo7gw .MuiDataGrid-columnHeaderDraggableContainer{
  border-right: 1px solid #b9e6fe !important;
} */


/* .css-n3fyjk-MuiDataGrid-root .MuiDataGrid-withBorderColor,.css-n3fyjk-MuiDataGrid-root .MuiDataGrid-cell {
  border-right: 1px solid #b9e6fe !important;
} */

.customDataGrid .MuiDataGrid-withBorderColor,
.customDataGrid .MuiDataGrid-cell {
  border-right: 1px solid #b9e6fe !important;
  border-bottom: 1px solid #b9e6fe !important;
}

/* mobile screen css start here */
/* signin css start */
.signin-container {
  height: 100vh;
  background-image: url("../assets/mobileassets/signInBackground.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem;
}

.signin-title {
  display: block;
  color: var(--white-color);
  font-size: 1.8rem;
}

.logo_img.mobile {
  width: 35%;
}

.mobileform-label {
  display: block;
  margin: 0.3rem 0rem;
  color: var(--white-color);
}

.form-feild {
  width: 100%;
}

.form-feild input {
  background-color: transparent !important;
  border: 0.1rem solid var(--white-color);
  padding: 0.8rem;
  border-radius: 0.8rem;
  color: var(--white-color);

}

.eyeicon {
  position: absolute;
  top: 2.7rem;
  right: 0.7rem;
  color: var(--white-color);
  font-size: 1.2rem;
}

.mobile-commonbutton {
  background-color: var(--common-bg-color);
  text-transform: capitalize;
  font-size: 1.2rem;
  border-radius: 0.4rem;
  padding: 0.3rem 1.3rem;
}

.contained {
  background-color: var(--brand-color);
}

.mobile-commonbutton.contained:hover {
  background-color: var(--brand-color);
}

.mobile-commonbutton:hover {
  background-color: var(--pending-bg-color);
}

/* signin css end */

/* sidebar css start */
.tabs-section div[aria-label="basic tabs example"] {
  padding: 0.5rem 2rem 0.5rem 0.5rem;
}

.tabs-section div[aria-label="basic tabs example"] button {
  font-size: 1rem;
  font-weight: 500;
  font-family: "Hauramedium", sans-serif;
  padding: 0.5rem 1rem;
  background-color: var(--header-text-color);
  border-radius: 10rem;
  min-height: 40px;
  text-transform: capitalize;
  letter-spacing: 0.03rem;
}

.tabs-section div[aria-label="basic tabs example"] button[aria-selected="true"] {
  background: var(--brand-color);
  color: var(--btntext-color);
  border-radius: 10rem;

}

.tabs-section div[aria-label="basic tabs example"]+span {
  display: none;
}

.tab-container {
  text-align: center;
  font-size: 1.5rem;
}

.tabs-section .tab-container>div {
  padding: 0rem;
  position: relative;
}

.sibebar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  /* gap: 1rem; */
  /* transition: all 0.77s linear; */
  list-style: none;
}

.sidebar-list {
  padding: 0.2rem 2.5rem 0rem 0.5rem;
}

.sidebaricon {
  font-size: 1.3rem;
  display: flex;
  align-items: center;
}

.sidebarlink {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 4px;
  text-decoration: none;
  font-size: 1rem;
  color: var(--brand-color);
  border-radius: 0.75rem;
}

.sidebarlink.active {
  background-color: var(--brand-color);
  color: var(--common-bg-color);
}

.sidebarlink.active .sidebarlink {
  color: var(--common-bg-color);
}

/* sidebar css end */

/* attendance map css start*/
main:has(.google-map, .calendar) {
  padding: 0px;
}

.google-map {
  position: relative;
}

.attendance-button {
  width: 100%;
  flex-direction: row;
  padding: 1rem;
  gap: 1rem;
  position: absolute;
  bottom: 1rem;
  z-index: 100;
}

.checkin {
  background-color: var(--payment-sucess) !important;
  color: var(--common-bg-color);
}

.checkout {
  background-color: var(--danger-color) !important;
  color: var(--common-bg-color);
}

/* attendance map css end */

/*Attendance logs css start*/
.calendar {
  overflow: hidden;
  /* padding-bottom: 10px; */
}

.calender-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  color: var(--card-time-color);
  font-size: 1rem;
  font-weight: 600;
  box-shadow: 0px 1px 3px 3px var(--card-time-color);
}

.calender-header button {
  background: none;
  color: var(--card-time-color);
  border: none;
  opacity: 0.7;
  cursor: pointer;
  font-size: 1.3rem;
}

.calender-header div {
  display: flex;
  gap: 1rem;
}

.calender-day--name,
.calender-date {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

.calender-day--name {
  background-color: var(--white-color);
  font-weight: bold;
  opacity: 0.5;
  margin-bottom: 1px;
}

.day-name,
.empty-day,
.day {
  width: 10.28%;
  padding: 8px;
  box-sizing: border-box;
  text-align: center;
  font-size: 1rem;
  opacity: 0.7;
  border-radius: 50%;
  margin: 0.1rem 0.50rem;
}

.day {
  cursor: pointer;
  transition: background-color 0.3s;
}

.today,
.selected {
  border: 1px solid var(--brand-color);
  border-radius: 50%;
  text-align: center;
}

/* .today.absent {
  background-color: var(--payment-sucess);
  color: var(--white-color);
} */

/* .selected {
  background-color: var(--payment-sucess);
} */

.present {
  background-color: var(--payment-sucess);
  color: var(--white-color);
}

.absent {
  background-color: var(--danger-color);
  color: var(--white-color);
}

.incomplete {
  background-color: var(--half-fay);
  color: var(--white-color);
}

.attendancestatus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 1rem;
}

.details.present {
  content: "";
  display: inline-block;
  border-radius: 50%;
  background-color: transparent;
  color: var(--payment-sucess);
}

.details.present::before,
.details.absent::before,
.details.incomplete::before {
  content: "";
  background-color: var(--payment-sucess);
  display: inline-block;
  padding: 0.4rem;
  margin: 0rem 0.5rem 0rem 0rem;
  border-radius: 50%;
}

.details.absent {
  background-color: transparent;
  color: var(--danger-color);
}

.details.absent::before {
  background-color: var(--danger-color);
}

.details.incomplete {
  background-color: transparent;
  color: var(--half-fay);
}

.details.incomplete::before {
  background-color: var(--half-fay);
}

.attendance-date {
  padding: 0 10px;
  color: var(--brand-color);
  font-size: 1.125rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}

.attendancedetails {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.attendanceinfo {
  display: flex;
}

.checkindoor-icon {
  font-size: 1.5rem;
  color: var(--payment-sucess);
  margin: 0rem 0.5rem;
}

.checkoutdoor-icon {
  font-size: 1.5rem;
  color: var(--danger-color);
  margin: 0rem 0.5rem;
}

.time {
  font-size: 1rem;
  font-weight: 500;
  color: var(--brand-color);
  margin-top: 5px;
}

.atten-address {
  font-size: 14px;
  line-height: 1.5;

}

/* Attendance logs css end */

/* Leave css start */
.container {
  padding: 0rem 0.5rem;
}

.container .mobileform-label {
  color: var(--dark-bg-color);
}

/* Leave css end */

@media (width <=412px) {

  .day-name,
  .empty-day,
  .day {
    padding: 6px;
    margin: 0.1rem 0.46rem;
  }

}

@media (width <=360px) {

  .day-name,
  .empty-day,
  .day {
    padding: 5px;
    margin: 0.1rem 0.4rem;
  }

}

/* mobile screen css end here */