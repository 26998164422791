
@font-face {
  font-family: "Haurabold", sans-serif;
  src: url("../public/hauora_sans/fonts/otf/Hauora-Bold.otf");
}
 
@font-face {
  font-family: "Haurasemibold", sans-serif;
  src: url("../public/hauora_sans/fonts/otf/Hauora-SemiBold.otf"),
}
 
@font-face {
  font-family: "Hauramedium", sans-serif;
  src: url("../public/hauora_sans/fonts/otf/Hauora-Medium.otf"),
}
 
@font-face {
  font-family: "kolekotif", sans-serif;
  src: url("../public/kollektif/Kollektif-Bold.ttf"),
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Hauramedium", sans-serif !important;
  
}


/* styles.css */
.disabled-row {
  pointer-events: none;
  opacity: 0.5;
}

.shortlist-row {
  background-color: rgba(226, 255, 226, 0.925) !important;
  /* pointer-events: none; */
}

.MuiDataGrid-checkboxInput.disabled-row .MuiCheckbox-root {
  display: none;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.fw-500{
font-weight: 500;
}