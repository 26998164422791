:root {
  --common-color: #026AA2;
  --commonBtn-color: #0B4A6F;
  --header-color: #062C41;
  --savebtn-color: #37668F;
  --btmbox-shadow: 0px 1px 1px rgba(85, 85, 85, 0.16);
  --sec-nav-btn-color: #98A2B3;
  --common-bg-color: #F4FAFF;
  --common-bg-text-color: #6b99be;
  --brand-color: #37668F;
  --header-text-color: #F4FAFF;
  --header-navlink-color: #98A2B3;
  --header-link-color: #1D2939;
  --btntext-color: #FFFFFF;
  --btnhover-color: #427DAF;
  --dark-bg-color: #1D2939;
  --dark-color: #F3FAFF;
  --light-bg-color: #F8FEFF;
  --light-color: #1D2939;
  --dark-card-color: #475467;
  --card-bg-color: #F0F9FF;
  --input-border-color: #D0D5DD;
  --twitter-color: #000000;
  --linked-color: #0077B5;
  --facebook-color: #1877F2;
  --input-color: #FCFCFD;
  --button-border-color: #98A2B3;
  --danger-color: #F04438;
  --dark-danger-color: #FDA29B;
  --placeholder-color: #999999;
  --dark-link-color: #36BFFA;
  --icon-color: #98A2B3;
  --icon-hover-color: #1D2939;
  --box-shadow-color: #888888;
  --sun-color: #FEC84B;
  --yellow-color: #D4AF37;
  --pink-color: #7A5AF8;
  --green-color: #388e3c;
  --transparent-color: #0505240f;
  --payment-sucess: #12B76A;
  --payment-failure: #F04438;
  --half-fay:#F79009;
  --payment-row-color: #E4E7EC;
  --sidebar-box-shadow-color: #D6D6D6;
  --home-image-color: rgba(0, 0, 0, 0.6);
  --white-color: #FCFCFD;
  --table-header-color: #d6edff;
  --pending-bg-color: #FFFAEB;
  --pending-color: #FDB022;
  --robot-chatbot-color: linear-gradient(to bottom, #9E77ED 100%, #5A4487 100%);
  --robot-chatbot-icon-color: linear-gradient(to bottom, #9E77ED 100%, #5A4487 100%);
  --user-color: linear-gradient(to bottom, #29ADB2 100%, #124A4C 100%);
  --robot-icon-color: linear-gradient(to bottom, #9E77ED 100%, #5A4487 100%);
  --robot-message-color: #584E65;
  --user-message-color: #3C5043;
  --send-button-color: #0B4A6F;
  --common-color: #026AA2;
  --commonBtn-color: #0B4A6F;
  --link-color: #0a58ca;
  --viewall-color: #026AA2;
  --card-name-color: #98A2B3;
  --card-time-color: #344054;
  --common-color: #026AA2;
  --commonBtn-color: #0B4A6F;
  --savebtn-color: #092C4C;
  --btmbox-shadow: 0px 1px 1px rgba(85, 85, 85, 0.16);
  --sec-nav-btn-color: #494949;
  --title-color:#667085;
}

.primarycolor {
  color: var(--common-color);
}

.common-bg-color {
  background-color: var(--common-bg-color);
}

.common-bg-text-color {
  color: var(--common-bg-text-color);
}
