.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



:root {
  --scrollbar-width: 3px;
  --scrollbar-thumb-color:var(--common-color);
}

/* For WebKit browsers */
::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
}

/* Specific targeting for DataGrid */
.MuiDataGrid-root::-webkit-scrollbar {
  width: 10%;
  height: 5px !important;
}

.MuiDataGrid-root::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
}


/* Prevents arrow key scrolling behavior for input[type="number"] */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.css-eqpfi5-MuiAccordionSummary-content.Mui-expanded , .css-eqpfi5-MuiAccordionSummary-content{
  font-size: large;
  font-weight: 400;
}

.css-pwcg7p-MuiCollapse-root {
  background-color: #F9FAFB;
  margin: 2;
}

.css-1rtad1{
  height: 12px !important;
  border-radius: 10px !important;
}

.css-1rtad1:hover{
  cursor: pointer !important;
}

.newshadow{
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.disabled {
  opacity: 0.6 !important;
  cursor: not-allowed !important;
}