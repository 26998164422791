/* -------  */

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-pointer-bg:hover {
  background-color: var(--common-bg-color);
  cursor: pointer !important;
}

.cursor-pointer-hover-bg {
  background-color: var(--common-bg-color);
}

.cursor-pointer-hover-bg:hover {
  cursor: pointer !important;
  background-color: var(--common-bg-color);
}

.css-1wc848c-MuiFormHelperText-root {
  color: red;
}

/* ul{
    box-shadow: none;
    padding: 0;
} */

.span-color {
  color: var(--common-color);
}

.heading {
  font-size: 1.8rem;
  font-weight: 400;
}

.commonBtn {
  background-color: var(--commonBtn-color);
  color: white;
  cursor: pointer;
  padding: 0.6rem 0rem;
  font-weight: 300;
}

.searchbar {
  padding: 0px !important;
  background-color: white;
}

.addBtn {
  background-color: var(--commonBtn-color);
  color: white;
  cursor: pointer;
  padding: 2rem 4rem;
  font-weight: 300;
}

.loginfields {
  width: 80%;
  margin: 10px 0px;
}

.colored-heading {
  color: var(--header-color) !important;
  font-size: 1.3rem;
  font-weight: 500;
}

.css-19kzrtu {
  padding: 0;
}

.saveBtn {
  background-color: var(--savebtn-color);
  padding: 5px 20px;
  color: white;
  text-transform: capitalize;
}

.saveBtn:hover {
  background-color: var(--common-color);
}

.sidebar-active-btn {
  background-color: var(--savebtn-color);
  padding: 3px;
  color: white;
  border-radius: 5px;
  margin-bottom: 3px;
  font-size: 14px;
}

.sidebar-active-btn:hover {
  background-color: var(--savebtn-color);
  padding: 3px;
  color: white;
  border-radius: 5px;
  margin-bottom: 3px;
}

.cancelBtn {
  padding: 5px 20px;
  background-color: white;
  color: var(--commonBtn-color);
  text-transform: capitalize;
}

.formInputs input,
.formInputs .selectInput {
  padding: 10px 15px !important;
}

.form-label {
  font-weight: 500;
  margin: 0.3rem 0rem;
}

.second-nav {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  padding: 7px 15px;
  /* box-shadow: var(--btmbox-shadow); */
}

.second-nav-btn {
  color: var(--sec-nav-btn-color);
  border: 1px solid #f2f4f7;
  font-size: 0.98rem;
  text-transform: capitalize;
  padding: 6px 12px;
  border-radius: 8px;
}

.secondnav-active-btn {
  color: var(--commonBtn-color);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px;
}

.thirdnav-active-btn {
  color: var(--commonBtn-color);
  box-shadow: rgba(0, 0, 0, 0.23) 0px 4px 4px;
  text-transform: capitalize;
  font-size: 1rem;
}

.active-nav-btn {
  font-size: 1.07rem;
  font-weight: 500;
  text-transform: capitalize;
  color: var(--common-color);
  box-shadow: rgba(0, 0, 0, 0.23) 0px 5px 5px;
  padding: 6px 12px;
}

.third-nav {
  background-color: #f9fafb;
}

.drawer-heading {
  font-size: 1.3rem;
  padding: 0.7rem 1.2rem;
}

.view-page-title {
  font-size: 1rem;
  font-weight: 400;
  color: #98a2b3;
}

.star {
  color: red !important;
}

.view-page-value {
  font-size: 1rem;
  font-weight: 400;
}

.fw-b {
  font-weight: bold;
}

.commonShadow {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.listShadow {
  box-shadow: -2px 1px 5px 0px rgba(40, 171, 199, 0.87);
  -webkit-box-shadow: -2px 1px 5px 0px rgba(40, 171, 199, 0.87);
  -moz-box-shadow: -2px 1px 5px 0px rgba(40, 171, 199, 0.87);
}

.pdfShadow {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.section-navbar {
  position: fixed;
  height: 60px;
  top: 0;
  z-index: 10;
  background-color: var(--common-bg-color);
  box-shadow: 0px 0px 1px 0px #1D2939;
}

.d-flex-align-center-justify-center-direction-column {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.d-flex-align-center-justify-center-direction-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.drawer-form-container {
  background-color: #f0f9ff !important;
  flex: 1;
}



.report-label {
  font-size: 20px;
  font-weight: 700;
}

.report-value {
  font-family: 700;
  font-size: 48px !important;
  color: var(--savebtn-color);
}

.report-card-bg {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 2;
}

.report-icon {
  font-size: 55px !important;
}

.br-2 {
  border-radius: 5px;
}

.fs-large {
  font-size: 18px;
}

.text-right {
  text-align: right;
}

.common-hover-box {
  transition: box-shadow 0.3s ease;
}

.common-hover-box:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


.bg-white {
  background-color: #fcfcfd !important;
}

/* notification css start */
.commonheading {
  font-weight: 600;
}

.main-parent--container {
  border: 0.15rem solid #8390af;
  background-color: var(--common-bg-color);
  color: var(--sec-nav-btn-color);
}

.notification-empty--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}

.message-avatar svg {
  font-size: 10rem;
  color: var(--brand-color);
}

.common-para {
  font-size: 1.5rem;
}

.common-desc {
  font-size: 1rem;
}

/* notification css end */

/* project css start */
.datefeild .MuiFormControl-root.MuiTextField-root {
  width: 100%;
  margin-top: 16px;
  margin-bottom: 8px;
}

/* project css end */

/* ess css start */
.myfavourites-data {
  margin-top: 0.5rem;
  font-size: 1.8rem;
  font-weight: 600;
  /* color:var(--danger-color) */
}

/* ess css end */