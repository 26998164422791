/* .payslip {
  width: 210mm;
  padding: 20mm;
  background: #fff;
  font-family: Arial, sans-serif;
  font-size: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: #333;
} */

.payslip {
  width: 210mm;
  padding: 10mm;
  background: #fff;
  font-family: Arial, sans-serif;
  font-size: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  left: -9999px;
  /* Hide the element offscreen */
}


.payslipHeader {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.payslipLogo {
  max-width: 180px;
  max-height: 70px;
  width: auto;
  height: auto;
  object-fit: contain;
  margin-right: 20px;
}

.payslipTitle {
  text-align: center;
  margin-bottom: 10px;
  background-color: #eeddcc;
  padding: 0.5rem;
}

.companyInfo {
  text-align: center;
  flex-grow: 1;
}

.companyInfo h2 {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}

.companyInfo p {
  margin: 0;
  font-size: 12px;
  color: #777;
}

.infoTable,
.earningsDeductions {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.infoTable td,
.earningsDeductions td,
.earningsDeductions th {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.infoTable td>span{
  display: inline-block;
  width: 50%;
}

.earningsDeductions thead {
  background-color: #f2f2f2;
}

.payslipFooter {
  margin-top: 20px;
  font-size: 12px;
  color: #333;
}

.payslipFooter p {
  margin: 4px 0;
}

.payslipFooter strong {
  font-weight: bold;
}

.hidden {
  visibility: hidden;
}